<section id="portfolio" class="portfolio-area section-padding" hidden>
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="section-title">
                    <h6 class="small-title">Latest Work</h6>
                    <h2>Recent Project</h2>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="portfolio-list">
                    <ul class="nav" id="portfolio-flters">
                        <li class="filter filter-active" data-filter=".all">all</li>
                        <li class="filter" data-filter=".branding">branding</li>
                        <li class="filter" data-filter=".application">application</li>
                        <li class="filter" data-filter=".webdesign">web design</li>
                        <li class="filter" data-filter=".photography">photography</li>
                    </ul>
                </div>
            </div>
            <div class="portfolio-container">
                <!-- portfolio-item -->
                <div class="col-lg-4 col-md-6 portfolio-grid-item all branding webdesign">
                    <a class="popup-img portfolio-item " href="/assets/img/projects/work-1.jpg"> <img src="/assets/img/projects/work-1.jpg" alt="img">
                        <div class="portfolio-img-overlay">
                            <div class="portfolio-content">
                                <h4>Creative Design</h4>
                                <h6>View Project</h6>
                            </div>
                        </div>
                    </a>
                </div>
                <!-- portfolio-item -->
                <div class="col-lg-4 col-md-6 portfolio-grid-item all application photography">
                    <a class="popup-img portfolio-item" href="/assets/img/projects/work-2.jpg"> <img src="/assets/img/projects/work-2.jpg" alt="img">
                        <div class="portfolio-img-overlay">
                            <div class="portfolio-content">
                                <h4>Creative Design</h4>
                                <h6>View Project</h6>
                            </div>
                        </div>
                    </a>
                </div>
                <!-- portfolio-item -->
                <div class="col-lg-4 col-md-6 portfolio-grid-item all branding webdesign">
                    <a class="popup-img portfolio-item " href="/assets/img/projects/work-3.jpg"> <img src="/assets/img/projects/work-3.jpg" alt="img">
                        <div class="portfolio-img-overlay">
                            <div class="portfolio-content">
                                <h4>Creative Design</h4>
                                <h6>View Project</h6>
                            </div>
                        </div>
                    </a>
                </div>
                <!-- portfolio-item -->
                <div class="col-lg-4 col-md-6 portfolio-grid-item all branding webdesig photographyn">
                    <a class="popup-img portfolio-item" href="/assets/img/projects/work-4.jpg"> <img src="/assets/img/projects/work-4.jpg" alt="img">
                        <div class="portfolio-img-overlay">
                            <div class="portfolio-content">
                                <h4>Creative Design</h4>
                                <h6>View Project</h6>
                            </div>
                        </div>
                    </a>
                </div>
                <!-- portfolio-item -->
                <div class="col-lg-4 col-md-6 portfolio-grid-item all application webdesign photography">
                    <a class="popup-img portfolio-item" href="/assets/img/projects/work-5.jpg"> <img src="/assets/img/projects/work-5.jpg" alt="img">
                        <div class="portfolio-img-overlay">
                            <div class="portfolio-content">
                                <h4>Creative Design</h4>
                                <h6>View Project</h6>
                            </div>
                        </div>
                    </a>
                </div>
                <!-- portfolio-item -->
                <div class="col-lg-4 col-md-6 portfolio-grid-item all branding photography">
                    <a class="popup-img portfolio-item" href="/assets/img/projects/work-6.jpg"> <img src="/assets/img/projects/work-6.jpg" alt="img">
                        <div class="portfolio-img-overlay">
                            <div class="portfolio-content">
                                <h4>Creative Design</h4>
                                <h6>View Project</h6>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>