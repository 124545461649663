<div class="col-md-10 offset-md-1">
  <div class="row contact-information">
    <div class="col-lg-4 col-md-6 col-sm-12" *ngFor="let c of listContact">
      <div class="contact-details">
        <i [ngClass]="c.icon"></i>
        <h6>{{c.title}}</h6>
        <p>{{c.content}}</p>
      </div>
    </div>
  </div>
</div>
