<section id="news" class="blog-area section-padding">
    <div class="container">
        <div class="row">
            <div class="col-md-12 section-title">
                <h6 class="small-title">Our Blog</h6>
                <h2>Latest News</h2>
            </div>
            <div class="col-lg-4 col-md-6 blog-item">
                <div class="item">
                    <div class="post-img position-re o-hidden">
                        <img src="/assets/img/blog/1.jpg" alt="">
                    </div>
                    <div class="content">
                        <span class="data">
                                    <a href="#0" class="undecro">Lifestyle</a>
                                    <a href="#0" class="undecro">February 27 - 2020</a>
                                </span>
                        <h5><a href="#0" class="undecro">Front-end developer website inspiration</a></h5>
                        <p>Lorem Ipsum is simply dummy text of the printing and type setting more recently dummy text ever since</p>
                        <a href="#" class="button" data-text="Read More"><span>Read More</span></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 blog-item">
                <div class="item">
                    <div class="post-img position-re o-hidden">
                        <img src="/assets/img/blog/2.jpg" alt="">
                    </div>
                    <div class="content">
                        <span class="data">
                                    <a href="#0" class="undecro">Lifestyle</a>
                                    <a href="#0" class="undecro">February 27 - 2020</a>
                                </span>
                        <h5><a href="#0" class="undecro">Front-end developer website inspiration</a></h5>
                        <p>Lorem Ipsum is simply dummy text of the printing and type setting more recently dummy text ever since</p>
                        <a href="#" class="button" data-text="Read More"><span>Read More</span></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 blog-item">
                <div class="item">
                    <div class="post-img position-re o-hidden">
                        <img src="/assets/img/blog/3.jpg" alt="">
                    </div>
                    <div class="content">
                        <span class="data">
                                    <a href="#0" class="undecro">Lifestyle</a>
                                    <a href="#0" class="undecro">February 27 - 2020</a>
                                </span>
                        <h5><a href="#0" class="undecro">Front-end developer website inspiration</a></h5>
                        <p>Lorem Ipsum is simply dummy text of the printing and type setting more recently dummy text ever since</p>
                        <a href="#" class="button" data-text="Read More"><span>Read More</span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>